// Gatsby requirements
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

// Components
import RichText from "./RichTextRenderer"

// Styles
import * as CSS from './SectionText.module.scss'


const SectionText = ({ data }) => {

    return (
        <section 
            className={`
                ${CSS.section}
                ${data?.backgroundImage?.gatsbyImageData !== null
                    && data?.backgroundImage?.gatsbyImageData !== undefined ?
                    CSS.hasBackground : ''
                }
            `}
        >

            {data?.richText && 
                <div className={CSS.richtext}>
                    <RichText content={data.richText} />
                </div>
            }

            {data?.backgroundImage?.gatsbyImageData &&
                <GatsbyImage
                    loading="lazy"
                    className={CSS.backgroundImage}
                    image={data.backgroundImage.gatsbyImageData}
                    alt={data.backgroundImage?.description}
                />
            }

        </section>
    )
}

export default SectionText
