// Gatsby requirements
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

// Components
import RichText from "./RichTextRenderer"

// Styles
import * as CSS from './SectionTextTextImage.module.scss'


const SectionTextTextImage = ({ data }) => {

    return (
        <section className={CSS.section}>

            {data?.richTextOnLeft &&
                <div className={CSS.textOnLeft}>
                    <RichText content={data.richTextOnLeft} />
                </div>
            }

            {data?.richTextOnRight && data?.imageOnRight?.gatsbyImageData &&
                <div className={CSS.rightSide}>
                    {data?.richTextOnRight &&
                        <div className={CSS.textOnRight}>
                            <div className={CSS.textOnRightContent}>
                                <RichText content={data.richTextOnRight} />
                            </div>
                        </div>
                    }

                    {data?.imageOnRight?.gatsbyImageData &&
                        <div className={CSS.image}>
                            <GatsbyImage
                                loading="lazy"
                                image={data.imageOnRight.gatsbyImageData}
                                alt={data.imageOnRight.description}
                            />
                        </div>
                    }
                </div>
            }

        </section>
    )
}

export default SectionTextTextImage
