// Gatsby requirements
import React from 'react'

// Components
import RichText from "./RichTextRenderer"

// Styles
import * as CSS from './SectionTableOfContents.module.scss'


const SectionTableOfContents = ({ data }) => {

    return (
        <section className={CSS.section}>

            {data?.richText && 
                <div>
                    <RichText content={data.richText} />
                </div>
            }
            
            {data.tableOfContents && 
                <nav aria-label="Table of contents" className={CSS.tableOfContents}>
                    <RichText content={data.tableOfContents} />
                </nav>
            }
            

        </section>
    )
}

export default SectionTableOfContents
