// Gatsby requirements
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import SectionText from '../components/SectionText'
import SectionFeaturedArticles from '../components/SectionFeaturedArticles'
import SectionContactUs from '../components/SectionContactUs'
import SectionCards from '../components/SectionCards'
import Footer from '../components/Layout/Footer'

// Components
import RichText from "./RichTextRenderer"

// Styles
import * as CSS from './ScrollSlide.module.scss'


const ScrollSlide = ({ data, index, length }) => {


    return (
        <section className={`${CSS.section} ${data.fillEntireWidth === true ? CSS.fillEntireWidth : ''} ${data.align === 'Center' ? CSS.alignCenter : ''}`}>

            {/* The slide number */}
            <span className={`${CSS.number} ${length === index + 1 ? CSS.numberLastSlide : ''}`} aria-hidden="true">{index < 10 ? '0' + (index + 1) : (index + 1)}</span>

            {/* Background image */}
            {data?.backgroundImage?.gatsbyImageData &&
                <GatsbyImage
                    loading={index === 0 ? 'eager' : 'lazy'}
                    className={CSS.backgroundImage}
                    image={data.backgroundImage.gatsbyImageData}
                    alt={data.backgroundImage.description}
                />
            }

            {/* Transparent outlined text */}
            {data?.transparentOutlinedText &&
                <span className={CSS.transparentOutlinedText}>{data.transparentOutlinedText}</span>
            }

            {/* The slide content */}
            <div className={CSS.leftSide}>
                {data.richText && 
                    <div className={CSS.richtext}>
                        {data.paragraphAboveRichText &&
                            <p className={CSS.paragraphAboveRichText}>{data.paragraphAboveRichText}</p>
                        }
                        <RichText content={data.richText} />
                    </div>
                }

                {/* Query blocks that should appear under text */}
                {data?.blocks &&
                    <>
                        {data?.blocks?.map((block, i) => {
                            switch (block.__typename) {

                                case "ContentfulSectionFeaturedArticles":
                                    return (
                                        <div className={CSS.leftSideBlocks}>
                                            <SectionFeaturedArticles key={i} data={block} />
                                        </div>
                                    )

                                case "ContentfulSectionCards":
                                    return (
                                        <div className={CSS.leftSideBlocks}>
                                            <SectionCards key={i} data={block} />
                                        </div>
                                    )
                                    
                                default:
                                    return false
                            }
                        })}
                    </>
                }
            </div>

            {/* Query blocks that should appear next to text */}
            {data?.blocks &&
                <>
                    {data?.blocks?.map((block, i) => {
                        switch (block.__typename) {

                            case "ContentfulSectionText":
                                return (
                                    <div className={`${CSS.rightSide} ${CSS.sectionText}`}>
                                        <SectionText key={i} data={block} />
                                    </div>
                                    )

                            case "ContentfulSectionContactUs":
                                return (
                                    <div className={`${CSS.rightSide} ${CSS.sectionContactUs}`}>
                                        <SectionContactUs className={CSS.rightSide} key={i} data={block} />
                                    </div>
                                    )
                                
                            default:
                                return false
                        }
                    })}
                </>
            }

            {length === index + 1 &&
                <div className={CSS.footer}>
                    <Footer/>
                </div>
            }

        </section>
    )
}

export default ScrollSlide
