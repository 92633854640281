// Gatsby requirements
import React, { useEffect } from "react"
import { graphql } from "gatsby"
import ReactFullpage from "@fullpage/react-fullpage";
import { Helmet } from 'react-helmet'

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Layout/Seo'
import ScrollSlide from '../components/ScrollSlide'
import Hero from '../components/Hero'
import SectionTextTextImage from '../components/SectionTextTextImage'
import SectionText from '../components/SectionText'
import SectionTableOfContents from '../components/SectionTableOfContents'
import SectionFeaturedArticles from '../components/SectionFeaturedArticles'
import SectionContactUs from '../components/SectionContactUs'
import SectionCards from '../components/SectionCards'
import Share from '../components/Share'

// Styles
import * as CSS from './Page.module.scss'


const PageTemplate = ({ data }) => {

    const page = data.contentfulPage;

    useEffect(() => {
      // Add ID to each H2 and H3
      var els = document.body.querySelectorAll("h2, h3");
      for (var i = 0; i < els.length; i++) {
          const textToId = [els[i].innerText.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')]
          els[i].setAttribute('id', textToId);
      };

      // If page is only one slide, add class to body
      if (page.pageContent?.length === 1) {
        document.body.classList.add("only-one-slide");
      }
    }, []);


    // This page template loops through all the used blocks on page
    // Usage: add new GraphQL content first, then add a component to the block loop
    return (
        <Layout url={page?.url}>

            {/* Helmet adds custom color to header links that lead to current page */}
            <Helmet>
              <style>{`header a[href="/${page?.url === 'home' ? '' : page?.url + '/'}"] {text-decoration: underline; color: #fff; text-decoration-color: var(--red); cursor: default;} header a[href="/${page?.url === 'home' ? '' : page?.url + '/'}"]:hover {color: var(--red)}`}</style>
            </Helmet>

            <Seo
                title={page?.pageTitle}
                metaTitle={page?.metaTitle}
                description={page?.metaDescription?.metaDescription}
                thumbnail={
                    page?.thumbnailImage?.file?.url && 
                    `https:${page?.thumbnailImage?.file?.url}?w=900&h=900&q=80`
                }
                type="website"
                url={page?.url === 'home' ? '/' : page.url}
            />

            <main id="content">

              {/* SCROLL-SNAPPING: Query all the ScrollSlide components on the page inside the <ReactFullpage/> */}
              {page?.enableScrollSnapping === true &&
                <ReactFullpage
                  licenseKey={"E6B2A261-1A6A4F9E-A6FDC6DC-9FB4166E"}
                  responsiveWidth="920"
                  responsiveHeight="820"
                  navigation
                  render={({fullpageApi}) => {
                    return (
                      <div id="fullpage-wrapper" >
                        {page?.pageContent?.map((block, index, i) => {
                            switch (block.__typename) {

                                case "ContentfulSectionScrollSlide":
                                    return (
                                      <div className={`section ${page.pageContent.length === index + 1 ? 'last-section' : ''}`}>
                                        <ScrollSlide length={page.pageContent.length} index={index} key={i} data={block} />
                                        
                                        {page.pageContent.length !== index + 1 &&
                                          <button className={`${index === 0 ? 'first-scroll-down-button' : ''} scroll-down-button`} onClick={() => fullpageApi.moveSectionDown()}>Scroll Down</button>
                                        }

                                        {page.pageContent.length === index + 1 &&
                                          <button className={`last-scroll-down-button scroll-down-button`} onClick={() => fullpageApi.moveTo(1)}>Scroll Up</button>
                                        }
                                      </div>
                                    )
                                    
                                default:
                                    return false
                            }
                        })}
                      </div>
                    );
                  }}
                />
              }

              {/* Show the Hero component if page is not snapping */}
              {page?.enableScrollSnapping !== true &&
                <Hero 
                  title={page?.heroTitle}
                  image={page?.thumbnailImage}
                  url={page?.url}
                />
              }

              {/* NON-SCROLL-SNAPPING: Query all the blocks on the page */}
              {page?.enableScrollSnapping !== true &&
                <div className={CSS.nonSnappingPage}>
                  {page?.pageContent?.map((block, i) => {
                      switch (block.__typename) {

                          case "ContentfulSectionTextTextImage":
                              return <SectionTextTextImage key={i} data={block} />

                          case "ContentfulSectionText":
                              return <SectionText key={i} data={block} />

                          case "ContentfulSectionTableOfContents":
                              return <SectionTableOfContents key={i} data={block} />

                          case "ContentfulSectionFeaturedArticles":
                              return <SectionFeaturedArticles key={i} data={block} />

                          case "ContentfulSectionCards":
                              return <SectionCards key={i} data={block} /> 

                          case "ContentfulSectionContactUs":
                              return <SectionContactUs key={i} data={block} /> 
                              
                          default:
                              return false
                      }
                  })}
                  <div className={CSS.pageFooter}>
                    <a className={CSS.scrollUp} href="#content">Scroll up</a>
                    {/* <Share url={page.url}/> */}
                  </div>
                </div>
              }

            </main>

            {/* Socials located on the sidebar */}
            {/* <aside aria-label="our social media" className={CSS.aside}>
              <a href="/"><img src="/icons/facebook.svg" alt="Visit our Facebook"/></a>
              <a href="/"><img src="/icons/linkedin.svg" alt="Visit our LinkedIn"/></a>
              <a href="/"><img src="/icons/twitter.svg" alt="Visit our Twitter"/></a>
            </aside> */}

        </Layout>
    )
}

// Add new Page blocks on this query, after pageContent
export const query = graphql`
  query ($slug: String!) {
    contentfulPage(url: { eq: $slug }) {
      id
      contentful_id
      
      url
      pageTitle
      metaTitle
      metaDescription {
        metaDescription
      }
      thumbnailImage {
        gatsbyImageData
        description
        file {
          url
        }
      }
      heroTitle {
        raw
      }

      enableScrollSnapping

      pageContent {
        __typename
        ...FragmentSectionText
        ...FragmentSectionTableOfContents
        ...FragmentSectionTextTextImage
        ...FragmentSectionFeaturedArticles
        ...FragmentSectionCards
        ...FragmentSectionContactUs
        
        ... on ContentfulSectionScrollSlide {
          id
          contentful_id
          backgroundImage {
            gatsbyImageData(quality: 60)
            description
          }
          paragraphAboveRichText
          fillEntireWidth
          align
          transparentOutlinedText
          richText {
            raw
            references {
              __typename
              ...FragmentRichTextButton
            }
          }
          blocks {
            __typename
            ...FragmentSectionText
            ...FragmentSectionFeaturedArticles
            ...FragmentSectionCards
            ...FragmentSectionContactUs
          }
        }

      }
    }
  }
`

export default PageTemplate
