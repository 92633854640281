// extracted by mini-css-extract-plugin
export var alignCenter = "ScrollSlide-module--alignCenter--+PQSx";
export var backgroundImage = "ScrollSlide-module--backgroundImage--4GqEg";
export var fillEntireWidth = "ScrollSlide-module--fillEntireWidth--sdUDe";
export var footer = "ScrollSlide-module--footer--R5G1t";
export var leftSide = "ScrollSlide-module--leftSide--9UTo+";
export var leftSideBlocks = "ScrollSlide-module--leftSideBlocks--NqHtq";
export var number = "ScrollSlide-module--number--yhoVI";
export var numberLastSlide = "ScrollSlide-module--numberLastSlide--uhADs";
export var paragraphAboveRichText = "ScrollSlide-module--paragraphAboveRichText--G9x0w";
export var richtext = "ScrollSlide-module--richtext--yIs0H";
export var rightSide = "ScrollSlide-module--rightSide--eYQJP";
export var section = "ScrollSlide-module--section--ur3we";
export var sectionContactUs = "ScrollSlide-module--sectionContactUs--o8Sba";
export var sectionText = "ScrollSlide-module--sectionText--KeX24";
export var transparentOutlinedText = "ScrollSlide-module--transparentOutlinedText--+UxsY";